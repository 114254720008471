import "./PreguntasFrecuentes.css";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { Elementos } from "./Components/FAQElements";

const PreguntasFrecuentes = () => {
  window.scrollTo(0, 0);
  const [data, setData] = useState({});
  const [Controlador, setControlador] = useState();

  const SendEmail = () =>
    (window.location = "mailto:soporte@lacamisetanosemancha.com");

  useEffect(() => {
    Elementos.filter((item) => item.Tema === Controlador).map((ide) =>
      setData({ ...ide })
    );
  }, [Controlador]);

  const RenderOfPreguntasFrecuentesHUB = (
    <div className="RenderOfPreguntasFrecuentesHUB-background">
      <div className="RenderOfPreguntasFrecuentesHUB-content">
        <p className="RenderOfPreguntasFrecuentesHUB-txt-1">
          Preguntas frecuentes
        </p>
        <div className="RenderOfPreguntasFrecuentesHUB-C-B1">
          <div className="RenderOfPreguntasFrecuentesHUB-C-B1B1">
            <Link
              to={"/preguntas_frecuentes/pagos"}
              className="RenderOfPreguntasFrecuentesHUB-txt-2"
            >
              Pagos <b className="RenderOfPreguntasFrecuentesHUB-txt-3">(5)</b>
            </Link>
            <div className="RenderOfPreguntasFrecuentesHUB-C-B1B1B1">
              <div className="RenderOfPreguntasFrecuentesHUB-C-B1B1B1B1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fillRule="currentColor"
                  className="RenderOfPreguntasFrecuentesHUB-svg-1"
                  viewBox="0 0 16 16"
                >
                  <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z" />
                </svg>
                <p
                  className="RenderOfPreguntasFrecuentesHUB-txt-4"
                  onClick={() =>
                    setControlador("¿Qué métodos de pagos aceptamos?")
                  }
                >
                  ¿Qué métodos de pagos aceptamos?
                </p>
              </div>
              <div className="RenderOfPreguntasFrecuentesHUB-C-B1B1B1B1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fillRule="currentColor"
                  className="RenderOfPreguntasFrecuentesHUB-svg-1"
                  viewBox="0 0 16 16"
                >
                  <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z" />
                </svg>
                <p
                  className="RenderOfPreguntasFrecuentesHUB-txt-4"
                  onClick={() =>
                    setControlador(
                      "¿Cómo funcionan los pagos con transferencia bancaria?"
                    )
                  }
                >
                  ¿Cómo funcionan los pagos con transferencia bancaria?
                </p>
              </div>
              <div className="RenderOfPreguntasFrecuentesHUB-C-B1B1B1B1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fillRule="currentColor"
                  className="RenderOfPreguntasFrecuentesHUB-svg-1"
                  viewBox="0 0 16 16"
                >
                  <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z" />
                </svg>
                <p
                  className="RenderOfPreguntasFrecuentesHUB-txt-4"
                  onClick={() =>
                    setControlador(
                      "¿Cómo funcionan los pagos con Mercado Pago?"
                    )
                  }
                >
                  ¿Cómo funcionan los pagos con Mercado Pago?
                </p>
              </div>
              <div className="RenderOfPreguntasFrecuentesHUB-C-B1B1B1B1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fillRule="currentColor"
                  className="RenderOfPreguntasFrecuentesHUB-svg-1"
                  viewBox="0 0 16 16"
                >
                  <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z" />
                </svg>
                <p
                  className="RenderOfPreguntasFrecuentesHUB-txt-4"
                  onClick={() =>
                    setControlador(
                      "¿Cuánto tiempo tengo para pagar con Transferencia o Efectivo?"
                    )
                  }
                >
                  ¿Cuánto tiempo tengo para pagar con Transferencia o Efectivo?
                </p>
              </div>
              <div className="RenderOfPreguntasFrecuentesHUB-C-B1B1B1B1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fillRule="currentColor"
                  className="RenderOfPreguntasFrecuentesHUB-svg-1"
                  viewBox="0 0 16 16"
                >
                  <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z" />
                </svg>
                <p
                  className="RenderOfPreguntasFrecuentesHUB-txt-4"
                  onClick={() =>
                    setControlador("¿Por qué mi pedido fue cancelado?")
                  }
                >
                  ¿Por qué mi pedido fue cancelado?
                </p>
              </div>
            </div>
          </div>
          <div className="RenderOfPreguntasFrecuentesHUB-C-B1B1">
            <Link
              to={"/preguntas_frecuentes/problemas_con_mi_pedido"}
              className="RenderOfPreguntasFrecuentesHUB-txt-2"
            >
              Problemas con mi pedido{" "}
              <b className="RenderOfPreguntasFrecuentesHUB-txt-3">(3)</b>
            </Link>
            <div className="RenderOfPreguntasFrecuentesHUB-C-B1B1B1">
              <div className="RenderOfPreguntasFrecuentesHUB-C-B1B1B1B1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fillRule="currentColor"
                  className="RenderOfPreguntasFrecuentesHUB-svg-1"
                  viewBox="0 0 16 16"
                >
                  <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z" />
                </svg>
                <p
                  className="RenderOfPreguntasFrecuentesHUB-txt-4"
                  onClick={() =>
                    setControlador(
                      "Ingresé mal mi dirección al hacer una compra"
                    )
                  }
                >
                  Ingresé mal mi dirección al hacer una compra
                </p>
              </div>
              <div className="RenderOfPreguntasFrecuentesHUB-C-B1B1B1B1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fillRule="currentColor"
                  className="RenderOfPreguntasFrecuentesHUB-svg-1"
                  viewBox="0 0 16 16"
                >
                  <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z" />
                </svg>
                <p
                  className="RenderOfPreguntasFrecuentesHUB-txt-4"
                  onClick={() =>
                    setControlador("Ordené el talle equivocado de un producto")
                  }
                >
                  Ordené el talle equivocado de un producto
                </p>
              </div>
              <div className="RenderOfPreguntasFrecuentesHUB-C-B1B1B1B1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fillRule="currentColor"
                  className="RenderOfPreguntasFrecuentesHUB-svg-1"
                  viewBox="0 0 16 16"
                >
                  <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z" />
                </svg>
                <p
                  className="RenderOfPreguntasFrecuentesHUB-txt-4"
                  onClick={() => setControlador("Quiero cancelar mi orden")}
                >
                  Quiero cancelar mi orden
                </p>
              </div>
            </div>
          </div>
          <div className="RenderOfPreguntasFrecuentesHUB-C-B1B1">
            <Link
              to={"/preguntas_frecuentes/envios"}
              className="RenderOfPreguntasFrecuentesHUB-txt-2"
            >
              Envíos <b className="RenderOfPreguntasFrecuentesHUB-txt-3">(4)</b>
            </Link>
            <div className="RenderOfPreguntasFrecuentesHUB-C-B1B1B1">
              <div className="RenderOfPreguntasFrecuentesHUB-C-B1B1B1B1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fillRule="currentColor"
                  className="RenderOfPreguntasFrecuentesHUB-svg-1"
                  viewBox="0 0 16 16"
                >
                  <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z" />
                </svg>
                <p
                  className="RenderOfPreguntasFrecuentesHUB-txt-4"
                  onClick={() =>
                    setControlador("Tiempo de entrega de los productos")
                  }
                >
                  Tiempo de entrega de los productos
                </p>
              </div>
              <div className="RenderOfPreguntasFrecuentesHUB-C-B1B1B1B1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fillRule="currentColor"
                  className="RenderOfPreguntasFrecuentesHUB-svg-1"
                  viewBox="0 0 16 16"
                >
                  <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z" />
                </svg>
                <p
                  className="RenderOfPreguntasFrecuentesHUB-txt-4"
                  onClick={() => setControlador("¿Quién envía el pedido?")}
                >
                  ¿Quién envía el pedido?
                </p>
              </div>
              <div className="RenderOfPreguntasFrecuentesHUB-C-B1B1B1B1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fillRule="currentColor"
                  className="RenderOfPreguntasFrecuentesHUB-svg-1"
                  viewBox="0 0 16 16"
                >
                  <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z" />
                </svg>
                <p
                  className="RenderOfPreguntasFrecuentesHUB-txt-4"
                  onClick={() =>
                    setControlador("No recibí mi confirmación por email")
                  }
                >
                  No recibí mi confirmación por email
                </p>
              </div>
              <div className="RenderOfPreguntasFrecuentesHUB-C-B1B1B1B1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fillRule="currentColor"
                  className="RenderOfPreguntasFrecuentesHUB-svg-1"
                  viewBox="0 0 16 16"
                >
                  <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z" />
                </svg>
                <p
                  className="RenderOfPreguntasFrecuentesHUB-txt-4"
                  onClick={() => setControlador("¿El envío es gratis?")}
                >
                  ¿El envío es gratis?
                </p>
              </div>
            </div>
          </div>
          <div className="RenderOfPreguntasFrecuentesHUB-C-B1B1">
            <Link
              to={"/preguntas_frecuentes/devoluciones"}
              className="RenderOfPreguntasFrecuentesHUB-txt-2"
            >
              Devoluciones{" "}
              <b className="RenderOfPreguntasFrecuentesHUB-txt-3">(3)</b>
            </Link>
            <div className="RenderOfPreguntasFrecuentesHUB-C-B1B1B1">
              <div className="RenderOfPreguntasFrecuentesHUB-C-B1B1B1B1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fillRule="currentColor"
                  className="RenderOfPreguntasFrecuentesHUB-svg-1"
                  viewBox="0 0 16 16"
                >
                  <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z" />
                </svg>
                <p
                  className="RenderOfPreguntasFrecuentesHUB-txt-4"
                  onClick={() =>
                    setControlador("Mis productos llegaron dañados")
                  }
                >
                  Mis productos llegaron dañados
                </p>
              </div>
              <div className="RenderOfPreguntasFrecuentesHUB-C-B1B1B1B1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fillRule="currentColor"
                  className="RenderOfPreguntasFrecuentesHUB-svg-1"
                  viewBox="0 0 16 16"
                >
                  <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z" />
                </svg>
                <p
                  className="RenderOfPreguntasFrecuentesHUB-txt-4"
                  onClick={() =>
                    setControlador("Me llegaron productos equivocados")
                  }
                >
                  Me llegaron productos equivocados
                </p>
              </div>
              <div className="RenderOfPreguntasFrecuentesHUB-C-B1B1B1B1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fillRule="currentColor"
                  className="RenderOfPreguntasFrecuentesHUB-svg-1"
                  viewBox="0 0 16 16"
                >
                  <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z" />
                </svg>
                <p
                  className="RenderOfPreguntasFrecuentesHUB-txt-4"
                  onClick={() =>
                    setControlador("Quiero un reembolso de mi compra")
                  }
                >
                  Quiero un reembolso de mi compra
                </p>
              </div>
            </div>
          </div>
          <div className="RenderOfPreguntasFrecuentesHUB-C-B1B1">
            <Link
              to={"/preguntas_frecuentes/nuestros_productos"}
              className="RenderOfPreguntasFrecuentesHUB-txt-2"
            >
              Nuestros productos{" "}
              <b className="RenderOfPreguntasFrecuentesHUB-txt-3">(4)</b>
            </Link>
            <div className="RenderOfPreguntasFrecuentesHUB-C-B1B1B1">
              <div className="RenderOfPreguntasFrecuentesHUB-C-B1B1B1B1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fillRule="currentColor"
                  className="RenderOfPreguntasFrecuentesHUB-svg-1"
                  viewBox="0 0 16 16"
                >
                  <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z" />
                </svg>
                <p
                  className="RenderOfPreguntasFrecuentesHUB-txt-4"
                  onClick={() => setControlador("¿Son originales?")}
                >
                  ¿Son originales?
                </p>
              </div>
              <div className="RenderOfPreguntasFrecuentesHUB-C-B1B1B1B1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fillRule="currentColor"
                  className="RenderOfPreguntasFrecuentesHUB-svg-1"
                  viewBox="0 0 16 16"
                >
                  <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z" />
                </svg>
                <p
                  className="RenderOfPreguntasFrecuentesHUB-txt-4"
                  onClick={() => setControlador("¿Van a reponer stock?")}
                >
                  ¿Van a reponer stock?
                </p>
              </div>
              <div className="RenderOfPreguntasFrecuentesHUB-C-B1B1B1B1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fillRule="currentColor"
                  className="RenderOfPreguntasFrecuentesHUB-svg-1"
                  viewBox="0 0 16 16"
                >
                  <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z" />
                </svg>
                <p
                  className="RenderOfPreguntasFrecuentesHUB-txt-4"
                  onClick={() =>
                    setControlador(
                      "¿Van a disponer de productos de más equipos de los que ya ofrecen?"
                    )
                  }
                >
                  ¿Van a disponer de productos de más equipos de los que ya
                  ofrecen?
                </p>
              </div>
              <div className="RenderOfPreguntasFrecuentesHUB-C-B1B1B1B1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fillRule="currentColor"
                  className="RenderOfPreguntasFrecuentesHUB-svg-1"
                  viewBox="0 0 16 16"
                >
                  <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z" />
                </svg>
                <p
                  className="RenderOfPreguntasFrecuentesHUB-txt-4"
                  onClick={() =>
                    setControlador("¿Los productos son nuevos o usados?")
                  }
                >
                  ¿Los productos son nuevos o usados?
                </p>
              </div>
            </div>
          </div>
          <div className="RenderOfPreguntasFrecuentesHUB-C-B1B1">
            <Link
              to={"/preguntas_frecuentes/talles"}
              className="RenderOfPreguntasFrecuentesHUB-txt-2"
            >
              Talles <b className="RenderOfPreguntasFrecuentesHUB-txt-3">(2)</b>
            </Link>
            <div className="RenderOfPreguntasFrecuentesHUB-C-B1B1B1">
              <div className="RenderOfPreguntasFrecuentesHUB-C-B1B1B1B1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fillRule="currentColor"
                  className="RenderOfPreguntasFrecuentesHUB-svg-1"
                  viewBox="0 0 16 16"
                >
                  <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z" />
                </svg>
                <p
                  className="RenderOfPreguntasFrecuentesHUB-txt-4"
                  onClick={() =>
                    setControlador(
                      "¿Se aplican los mismos talles para todos los productos?"
                    )
                  }
                >
                  ¿Se aplican los mismos talles para todos los productos?
                </p>
              </div>
              <div className="RenderOfPreguntasFrecuentesHUB-C-B1B1B1B1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fillRule="currentColor"
                  className="RenderOfPreguntasFrecuentesHUB-svg-1"
                  viewBox="0 0 16 16"
                >
                  <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z" />
                </svg>
                <p
                  className="RenderOfPreguntasFrecuentesHUB-txt-4"
                  onClick={() => setControlador("¿Los talles son exactos?")}
                >
                  ¿Los talles son exactos?
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const RenderOfFaqElement = (
    <div className="RenderOfFaqElement-background">
      <div className="RenderOfFaqElement-content">
        <div className="RenderOfFaqElement-C-B1">
          <p className="RenderOfFaqElement-txt-1">{data.Tema}</p>
        </div>
        <p className="RenderOfFaqElement-txt-2">{data.Descripcion}</p>
        <div className="RenderOfFaqElement-C-B2">
          <p
            className="RenderOfFaqElement-txt-3"
            onClick={() => setControlador()}
          >
            Volver
          </p>
        </div>
      </div>
    </div>
  );

  return (
    <div className="PreguntasFrecuentes-background">
      <div className="PreguntasFrecuentes-content">
        <div className="PreguntasFrecuentes-C-B1"></div>
        <div className="PreguntasFrecuentes-C-B2">
          <div className="PreguntasFrecuentes-C-B2B1">
            <p className="PreguntasFrecuentes-txt-1">TE AYUDAMOS</p>

            <p className="PreguntasFrecuentes-txt-2">
              LA CAMISETA NO SE MANCHA{" "}
              <b className="PreguntasFrecuentes-txt-3">SOPORTE</b>
            </p>
          </div>
          <div className="PreguntasFrecuentes-C-B2B2">
            <p className="PreguntasFrecuentes-txt-4">
              ¿En qué podemos ayudarte hoy?
            </p>
            <div className="PreguntasFrecuentes-C-B2B2B1">
              <div
                className="PreguntasFrecuentes-C-B2B2B1B1"
                onClick={() => SendEmail()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fillRule="currentColor"
                  className="PreguntasFrecuentes-svg-1"
                  viewBox="0 0 16 16"
                >
                  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
                </svg>
                <p className="PreguntasFrecuentes-txt-5">
                  Contáctanos por Email
                </p>
              </div>
              <Link
                to={"/seguimiento"}
                className="PreguntasFrecuentes-C-B2B2B1B1"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fillRule="currentColor"
                  className="PreguntasFrecuentes-svg-1"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M6 2a.5.5 0 0 1 .47.33L10 12.036l1.53-4.208A.5.5 0 0 1 12 7.5h3.5a.5.5 0 0 1 0 1h-3.15l-1.88 5.17a.5.5 0 0 1-.94 0L6 3.964 4.47 8.171A.5.5 0 0 1 4 8.5H.5a.5.5 0 0 1 0-1h3.15l1.88-5.17A.5.5 0 0 1 6 2Z"
                  />
                </svg>
                <p className="PreguntasFrecuentes-txt-5">
                  Seguí el estado de tu orden
                </p>
              </Link>
              <a
                href={"https://wa.me/5493874064060"}
                target={"_blank"}
                rel="noreferrer"
                className="PreguntasFrecuentes-C-B2B2B1B1"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fillRule="currentColor"
                  className="PreguntasFrecuentes-svg-1"
                  viewBox="0 0 16 16"
                >
                  <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
                </svg>
                <p className="PreguntasFrecuentes-txt-5">
                  Chatéanos @ +54 9 387-4064060
                </p>
              </a>
            </div>
          </div>
          <div className="PreguntasFrecuentes-C-B2B3">
            {Controlador === undefined ? RenderOfPreguntasFrecuentesHUB : null}
            {Controlador !== undefined ? RenderOfFaqElement : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreguntasFrecuentes;
